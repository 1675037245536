<div class="form-edit-inline-container">
  <div class="ax-office-layout__content-headline">
    <ng-content select="[form-edit-headline]" />
    @if (canUpdate) {
      <div class="flex end">
        <ax-ui-button (buttonClick)="emitEditModeChange(true)"
                      [iconOnly]="true"
                      [class.fade-out-opacity-only]="editMode"
                      [class.fade-in-opacity-only]="!editMode"
                      [tooltipDescription]="'DATEN_BEARBEITEN'"
                      iconName="edit"
                      variant="secondary" />
      </div>
    }
  </div>

  <ng-content select="[form-edit-content]" />

  <ax-ui-sticky-footer
    padding="compact"
    [animationState]="editMode ? 'show' : 'hide'"
    [hideOnInit]="true">
    <div class="flex flex-row space-between vertical-center gap-24">
      <div class="flex flex-row vertical-center gap-12">
        <ax-ui-icon name="edit"
                    theme="success"
                    [framed]="true" />
        <p><b>{{ 'BEARBEITUNGSMODUS' | translate }}</b></p>
      </div>
      <div class="ax-buttons">
        <ax-ui-button
          (buttonClick)="cancelEdit()"
          variant="secondary">
          {{ 'ABBRECHEN' | translate }}
        </ax-ui-button>
        <ax-ui-button (buttonClick)="emitSaveEvent()"
                      [(loadingState)]="formSaveLoadingState"
                      [disabled]="formGroupUpdate.invalid || !formGroupUpdate.dirty"
                      [showLoadingOnClick]="true">
          {{ 'SPEICHERN' | translate }}
        </ax-ui-button>
      </div>
    </div>
  </ax-ui-sticky-footer>
</div>
