import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getProjectStatusColorVariantPipe',
  standalone: true,
})
export class GetProjectStatusColorVariantPipe implements PipeTransform {
  transform(statusId: number | null | undefined): 'neutral' | 'success' | 'warning' | 'error' | 'empty' {
    switch (statusId) {
      case 4:
      case 5:
        return 'warning';
      case 6:
      case 7:
        return 'success';
      default:
        return 'neutral';
    }
  }
}
