import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { LoadingAndFeedbackState } from '../../models/loading-and-feedback-state.type';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

export type ButtonVariant = 'primary' | 'primary-white' | 'secondary' | 'tertiary' | 'inactive' | 'warning';
export type ButtonType = 'button' | 'submit' | 'reset';

@Component({
  selector: 'ax-ui-button',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    IconComponent,
    TranslateModule,
    LoadingSpinnerComponent,
    RouterLinkActive,
    TooltipDirective
  ],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() text = '';
  @Input() additionalInfoText = '';
  @Input() routerLink: any = undefined;
  @Input() htmlLink = '';
  @Input() htmlLinkOpenNewTab = false;
  @Input() fileDownloadName = '';
  @Input() linkStyling = false;
  @Input() variant: ButtonVariant = 'primary';
  @Input() type: ButtonType = 'button';
  @Input() disabled = false;
  @Input() small = false;
  @Input() fullWidth = false;
  @Input() fullWidthMobile = false;
  @Input() maxWidth: number | undefined;
  @Input() width: number | undefined;
  @Input() floating = false;
  @Input() iconName = '';
  @Input() iconPositionRight = false;
  @Input() iconOnly = false;
  @Input() iconExternalSrc = '';
  @Input() iconPadding: number | undefined;
  @Input() showLoadingOnClick = false;
  @Input() loadingState: LoadingAndFeedbackState = 'none';
  @Input() whiteBackground = false;
  @Input() alignRight = false;
  @Input() alignCenter = false;
  @Input() queryParams: any;
  @Input() active = false;
  @Input() tooltipDescription = '';
  @Input() copyTextToClipboard = '';

  @Output() loadingStateChange: EventEmitter<LoadingAndFeedbackState> = new EventEmitter<LoadingAndFeedbackState>();
  @Output() buttonClick = new EventEmitter();

  public buttonClicked = false;
  public initFinished = false;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  get nativeElement() {
    return this.elementRef.nativeElement;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.initFinished = true;
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadingState'] && changes['loadingState'].currentValue) {
      switch (changes['loadingState'].currentValue as LoadingAndFeedbackState) {
        case 'loading': {
          this.showLoadingAnimation();
          break;
        }
        case 'success': {
          this.loadingStateChange.emit('success');
          break;
        }
        case 'error': {
          this.loadingStateChange.emit('error');
          break;
        }
        case 'none': {
          this.loadingStateChange.emit('none');
          break;
        }
      }
    }
  }

  ngOnDestroy() {
    try {
      this.loadingStateChange.unsubscribe();
      this.buttonClick.unsubscribe();
    } catch {
      // nothing to catch
    }
  }

  public async handleClick(event: any) {
    this.buttonClick.emit(event);
    if (this.copyTextToClipboard) {
      await navigator.clipboard.writeText(this.copyTextToClipboard);
    }
    if (this.showLoadingOnClick) {
      this.showLoadingAnimation();
    }
  }

  /**
   * Show loading icon on click
   */
  private showLoadingAnimation() {
    this.loadingStateChange.emit('loading');
    this.loadingState = 'loading';
    this.buttonClicked = true;
  }
}
