import { AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[axAutofocus]',
  standalone: true,
})
export class AutofocusDirective implements AfterViewInit, OnDestroy {
  private focusCheckInterval: number | null = null;

  constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly renderer2: Renderer2) {}

  ngAfterViewInit(): void {
    const focusableElement = this.getFocusableElement();

    if (focusableElement) {
      this.focusCheckInterval = window.setInterval(() => {
        if (document.activeElement !== focusableElement) {
          focusableElement.focus();
        } else {
          this.clearFocusInterval(); // Stop interval once focused
        }
      }, 50); // Check every 50ms
    } else {
      console.warn('[axAutofocus] No focusable element found inside the host component.');
    }
  }

  private getFocusableElement(): HTMLInputElement | HTMLTextAreaElement | null {
    const nativeElement = this.elementRef.nativeElement;

    // If the host element itself is focusable (custom components)
    if (nativeElement.tagName === 'INPUT' || nativeElement.tagName === 'TEXTAREA') {
      return nativeElement as HTMLInputElement | HTMLTextAreaElement;
    }

    // Search for <input> or <textarea> inside the host component
    const focusableElement = nativeElement.querySelector('input, textarea');
    return focusableElement as HTMLInputElement | HTMLTextAreaElement | null;
  }

  private clearFocusInterval(): void {
    if (this.focusCheckInterval !== null) {
      clearInterval(this.focusCheckInterval);
      this.focusCheckInterval = null;
    }
  }

  ngOnDestroy(): void {
    this.clearFocusInterval(); // Ensure cleanup if directive is destroyed
  }
}

