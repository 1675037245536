import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { dropDownAlignment, DropdownListComponent } from '../dropdown-list/dropdown-list.component';
import { ConfigurationKeyNamesEnum, DropdownOption, LoggerService } from '@axova-frontend-monorepo/axova-commons';
import { AxUserconfigurationsV2Service } from '@axova-frontend-monorepo/axova-rest-api';
import { lastValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { FilterState, FilterStateSetFilterOptions } from '@axova-frontend-monorepo/axova-state';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'ax-ui-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    TranslateModule,
    DropdownListComponent,
  ],
})
export class FilterComponent implements OnInit {
  @ViewChild('filterDropdown') dropdown!: DropdownListComponent;

  @Input({ required: true }) filterDropdownOptions!: DropdownOption[];
  @Input({ required: true }) filterConfigKeyName!: ConfigurationKeyNamesEnum;
  @Input() storageLocation: 'api' | 'state' = 'api';
  @Input() allowOnlyOneActiveFilter = false;
  @Input() smallButton = false;
  @Input() disabled = false;
  @Input() filterTitle = '';

  @Output() selectedFiltersChange: EventEmitter<DropdownOption[]> = new EventEmitter<DropdownOption[]>();

  public selectedFilters: DropdownOption[] = [];
  public dropDownAlignment: dropDownAlignment = 'bottom';
  public selectedFiltersButtonText = this.translateService.instant('FILTER');
  public numberOfSelectedFiltersText = '';

  private selectedFiltersEmpty = false;
  private storedFilterOptions: DropdownOption[] = [];

  constructor(
    private readonly translateService: TranslateService,
    private readonly elementRef: ElementRef,
    private readonly axUserconfigurationsV2Service: AxUserconfigurationsV2Service,
    private readonly store: Store,
  ) {
  }

  @HostListener('document:click', ['$event.target'])
  public onDocumentClick(targetElement: HTMLElement): void {
    if (this.dropdown) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement);
      if (!clickedInside && this.dropdown.dropDownOpen) {
        this.dropdown.dropDownOpen = false;
        this.dropdown.resetSelection();
        this.filterDropdownOptions = this.dropdown.filteredOptions;
        this.setSelectedFilters();
        this.setSelectedFiltersButtonText();
      }
    }
  }

  async ngOnInit() {
    await this.loadExistingFilterConfiguration();
    await this.processStoredData();
  }

  public addActiveFilter(dropdownOption: DropdownOption) {
    if (this.allowOnlyOneActiveFilter) {
      this.selectedFilters.forEach((filter) => {
        this.removeFilter(filter);
      });
    }
    const option = this.filterDropdownOptions.find((option) => option.name === dropdownOption.name && option.value === dropdownOption.value);
    if (option) {
      this.selectedFilters.push(option);
      option.selected = true;
      this.setSelectedFiltersButtonText();
    }
  }

  public removeFilter(filter: DropdownOption) {
    if (this.selectedFiltersEmpty) {
      this.filterDropdownOptions = [];
      this.selectedFiltersEmpty = false;
    }
    // remove filter
    this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1);
    const found = this.filterDropdownOptions.find((option) => option.name === filter.name && option.value === filter.value);
    if (found) {
      found.selected = false;
    }
    this.setSelectedFiltersButtonText();
  }

  public async onSelectedCheckboxOptionsChange() {
    await this.saveNewFilterConfiguration();
    this.selectedFiltersChange.emit(this.filterDropdownOptions);
  }

  private async processStoredData() {
    // if options from parent are empty, it has most likely to do with the API,
    // therefore state should not be updated
    if (this.filterDropdownOptions.length === 0) {
      return;
    }

    // check if options from parent input are different from the stored options in the state (e.g. introduction of new options or new properties in the DropdownOption model)
    // and if selected values are different to assure the user's selected options are kept correctly in the state
    const comparisonResult = this.deepCompareArrayOptionsForChangesOrSelectedDiffers(this.filterDropdownOptions, this.storedFilterOptions);
    if (this.storedFilterOptions.length === 0 || comparisonResult.hasChanges) {
      // detected options changes from the parent component, update state
      // Merge 'selected' status from state into incoming parent options
      this.mergeSelectedStatus(this.filterDropdownOptions, this.storedFilterOptions);
      await this.saveNewFilterConfiguration();
      // make a deep copy to solve object read only problem
      this.filterDropdownOptions = JSON.parse(JSON.stringify(this.filterDropdownOptions));
    } else if (comparisonResult.selectedDiffers) {
      // no options changes from the parent component, but selected options defer from saved options in state
      this.filterDropdownOptions = JSON.parse(JSON.stringify(this.storedFilterOptions));
    }
    this.selectedFilters = [];
    this.filterDropdownOptions.forEach((option) => {
      option.selected = option.selected === undefined ? false : option.selected;
      if (option.selected) {
        this.addActiveFilter(option);
      }
    });
    this.selectedFiltersChange.emit(this.filterDropdownOptions);

    // use setTimeout to assure correct text showing
    setTimeout(() => {
      this.setSelectedFiltersButtonText();
    });
  }

  private setSelectedFiltersButtonText() {
    this.numberOfSelectedFiltersText = this.dropdown.numberOfSelectedCheckboxes > 1 ? '(+' + (this.dropdown.numberOfSelectedCheckboxes - 1).toString() + ')' : '';
    if (this.selectedFilters.length > 0) {
      const firstSelectedFilter = this.selectedFilters[0];
      this.selectedFiltersButtonText = firstSelectedFilter.group ? `${this.translateService.instant(firstSelectedFilter.group)}: ${this.translateService.instant(firstSelectedFilter.label)}` : firstSelectedFilter.label;
    } else {
      this.selectedFiltersButtonText = this.translateService.instant(this.filterTitle || 'FILTER');
    }
  }

  private setSelectedFilters() {
    // reset selected filters
    this.selectedFilters = [];
    // populate with new values
    this.filterDropdownOptions.forEach((option) => {
      if (option.selected) {
        this.selectedFilters.push(option);
      }
    });
  }

  private async loadExistingFilterConfiguration() {
    if (this.storageLocation === 'api') {
      try {
        const userconfiguration = await lastValueFrom(
          this.axUserconfigurationsV2Service.userconfigurationsControllerGetMyUseronfigurationByKey({
            configurationKey: this.filterConfigKeyName,
          }),
        );
        if (userconfiguration && userconfiguration.configurationValue) {
          this.storedFilterOptions = JSON.parse(userconfiguration.configurationValue);
        }
      } catch (noExistingFilterstateFoundException) {
        LoggerService.ERROR(this, 'noExistingFilterstateFoundException', noExistingFilterstateFoundException);
      }
    } else {
      this.storedFilterOptions = this.store.selectSnapshot(FilterState.singleFilterOptions(this.filterConfigKeyName));
    }
  }

  private async saveNewFilterConfiguration() {
    if (this.storageLocation === 'api') {
      try {
        await lastValueFrom(
          this.axUserconfigurationsV2Service.userconfigurationsControllerCreateOrUpdate({
            body: {
              configurationKey: this.filterConfigKeyName,
              configurationValue: JSON.stringify(this.filterDropdownOptions),
            },
          }),
        );
      } catch (filterNotStoreException) {
        LoggerService.ERROR(this, 'filterNotStoreException', filterNotStoreException);
      }
    } else {
      this.store.dispatch(
        new FilterStateSetFilterOptions({
          name: this.filterConfigKeyName,
          options: this.filterDropdownOptions,
        }),
      );
    }
  }

  private deepCompareObjects(obj1: any, obj2: any): boolean {
    // Ensure both objects have a 'group' property for comparison
    if (!Object.prototype.hasOwnProperty.call(obj1, 'group')) obj1.group = undefined;
    if (!Object.prototype.hasOwnProperty.call(obj2, 'group')) obj2.group = undefined;

    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) return false;

    const keys1 = Object.keys(obj1).filter((key) => key !== 'selected');
    const keys2 = Object.keys(obj2).filter((key) => key !== 'selected');

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key)) return false;
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (!this.deepCompareObjects(obj1[key], obj2[key])) return false;
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  private deepCompareArrayOptionsForChangesOrSelectedDiffers(
    array1: any[],
    array2: any[],
  ): {
    hasChanges: boolean;
    selectedDiffers: boolean;
  } {
    const result = { hasChanges: false, selectedDiffers: false };

    if (array1.length !== array2.length) {
      result.hasChanges = true;
      return result;
    }

    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      // Check for selected property difference
      const selected1 = obj1.selected === undefined ? false : obj1.selected;
      const selected2 = obj2.selected === undefined ? false : obj2.selected;
      if (selected1 !== selected2) {
        result.selectedDiffers = true;
      }

      // Exclude 'selected' property for deep comparison
      const { selected: _, ...obj1WithoutSelected } = obj1;
      const { selected: __, ...obj2WithoutSelected } = obj2;

      if (!this.deepCompareObjects(obj1WithoutSelected, obj2WithoutSelected)) {
        result.hasChanges = true;
        break; // Found a change, no need to check further
      }
    }

    return result;
  }

  private mergeSelectedStatus(incomingOptions: DropdownOption[], currentOptions: DropdownOption[]) {
    incomingOptions.forEach((option) => {
      // Find a matching option in currentOptions based on unique identifier value
      const match = currentOptions.find((currentOption) => currentOption.name === option.name && currentOption.value === option.value);
      if (match) {
        option.selected = match.selected;
      }
      // If there's no matching 'group' property, add it as undefined
      if (!Object.prototype.hasOwnProperty.call(option, 'group')) {
        option.group = undefined;
      }
    });
  }
}
