<label (click)="toggleCheckbox()"
       [class.disabled]="disabled"
       [class.readonly]="readonly"
       [class.margin-none]="noMargin"
       [for]="id"
       class="ax-ui-checkbox">
  @if (labelBold) {
    <b>{{ label | translate }}</b>
  } @else {
    {{ label | translate }}
  }
  <ng-content></ng-content>
  <input
    (blur)="onTouched()"
    [checked]="checked"
    [disabled]="disabled"
    [id]="id"
    [name]="name"
    type="checkbox" />
  <span class="ax-ui-checkbox__checkmark"></span>
</label>