import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { getFormattedDate } from '../functions/get-formatted-date';

@Pipe({
  name: 'formatDateTimeWithSameDayCheck',
  standalone: true,
})
export class formatDateTimeWithSameDayCheck implements PipeTransform {
  transform(startDate: string | Date | undefined | null, endDate: string | Date | undefined | null): string {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const isSameDay = start.isSame(end, 'day');

    // Return time only if the same day, otherwise the full formatted date
    return isSameDay ? end.format('HH:mm') + ' Uhr' : getFormattedDate(endDate, 'DD.MM.YYYY, HH:mm [Uhr]');
  }
}
