@if (routerLink) {
  <a (click)="!disabled ? handleClick($event) : undefined"
     [ngClass]="{
        'small': small,
        'disabled': disabled,
        'floating': floating,
        'with-icon': iconName,
        'icon-right': iconPositionRight,
        'icon-only': iconOnly,
        'no-text': text==='',
        'white': whiteBackground,
        'full-width': fullWidth,
        'full-width-mobile': fullWidthMobile,
        'loading' : loadingState === 'loading',
        'align-right' : alignRight,
        'align-center' : alignCenter,
        'transitions' : initFinished,
        'active' : active,
        'link-style' : linkStyling,
        }"
     [queryParams]="queryParams ? queryParams : undefined"
     [routerLink]="(routerLink && !disabled) ? routerLink : undefined"
     [style.max-width]="maxWidth ? maxWidth + 'px' : null"
     [style.padding]="iconPadding + 'px'"
     [style.width]="width? width + 'px': null"
     [style.height]="width && iconOnly ? width + 'px' : null"
     [type]="type"
     [axUiTooltip]="tooltipDescription | translate"
     routerLinkActive="active-page"
     ariaCurrentWhenActive="page"
     class="ax-ui-button ax-button {{variant}}">
    <ng-template [ngTemplateOutlet]="innerButton"></ng-template>
  </a>
} @else {
  @if (htmlLink) {
    <a (click)="!disabled ? handleClick($event) : undefined"
       [href]="htmlLink"
       [ngClass]="{
        'small': small,
        'disabled': disabled,
        'floating': floating,
        'with-icon': iconName,
        'icon-right': iconPositionRight,
        'icon-only': iconOnly,
        'no-text': text==='',
        'white': whiteBackground,
        'full-width': fullWidth,
        'full-width-mobile': fullWidthMobile,
        'loading' : loadingState === 'loading',
        'align-right' : alignRight,
        'align-center' : alignCenter,
        'transitions' : initFinished,
        'active' : active,
        'link-style' : linkStyling,
        }"
       [style.max-width]="maxWidth ? maxWidth + 'px' : null"
       [style.padding]="iconPadding + 'px'"
       [style.width]="width? width + 'px': null"
       [style.height]="width && iconOnly ? width + 'px' : null"
       [target]="htmlLinkOpenNewTab ? '_blank' : ''"
       [type]="type"
       [download]="fileDownloadName"
       [axUiTooltip]="tooltipDescription | translate"
       class="ax-ui-button ax-button {{variant}}">
      <ng-template [ngTemplateOutlet]="innerButton"></ng-template>
    </a>
  } @else {
    <button (click)="!disabled ? handleClick($event) : undefined"
            [disabled]="disabled"
            [ngClass]="{
        'small': small,
        'floating': floating,
        'with-icon': iconName,
        'icon-right': iconPositionRight,
        'icon-only': iconOnly,
        'no-text': text==='',
        'white': whiteBackground,
        'full-width': fullWidth,
        'full-width-mobile': fullWidthMobile,
        'loading' : loadingState === 'loading',
        'align-right' : alignRight,
        'align-center' : alignCenter,
        'transitions' : initFinished,
         'active' : active,
        }"
            [style.max-width]="maxWidth ? maxWidth + 'px' : null"
            [style.padding]="iconPadding + 'px'"
            [style.width]="width? width + 'px': null"
            [style.height]="width && iconOnly ? width + 'px' : null"
            [type]="type"
            [axUiTooltip]="tooltipDescription | translate"
            class="ax-ui-button ax-button {{variant}}">
      <ng-template [ngTemplateOutlet]="innerButton"></ng-template>
    </button>
  }
}



<ng-template #innerButton>
  <span [class.hide]="loadingState !== 'none' && !!loadingState"
        [class.show]="buttonClicked && loadingState === 'none'"
        [ngClass]="iconOnly ? 'center' : 'space-between'"
        class="ax-ui-button__text flex flex-row vertical-center width-100 ax-loading-element show-on-init">
    @if (iconName) {
      <ax-ui-icon class="color-unset" [boldness]="400" [fontSize]="floating ? 48 : small || linkStyling ? 18 : 24"
                  [name]="iconName" />
    }
    @if (iconExternalSrc) {
      <img [src]="iconExternalSrc" alt="Icon">
    }
    <div class="flex gap-8 center width-100">
      <span class="text-overflow-ellipsis">
        @if (small) {
          <small>{{ text | translate }}</small>
        }
        @if (!small) {
          <span>{{ text | translate }}</span>
        }
      </span>
      @if (additionalInfoText) {
        <span>
          @if (small) {
            <small>{{ additionalInfoText | translate }}</small>
          }
          @if (!small) {
            <span>{{ additionalInfoText | translate }}</span>
          }
        </span>
      }
    </div>
    <ng-content />
  </span>
  <ax-ui-loading-spinner [(loadingState)]="loadingState" [iconSize]="floating ? 48 : 24" />
</ng-template>
