import { AxLocation, AxReservation, AxReservationtype } from '@axova-frontend-monorepo/axova-rest-api';

export class TerminassistentStateSetLocation {
  static readonly type = '[Terminassistent] Set location';

  constructor(public location: AxLocation) {
  }
}

export class TerminassistentStateSetSearchedLocation {
  static readonly type = '[Terminassistent] Set searched location';

  constructor(public searchedLocation: string) {
  }
}

export class TerminassistentStateSetReservationtype {
  static readonly type = '[Terminassistent] Set reservation type';

  constructor(public reservationtype: Partial<AxReservationtype>) {
  }
}

export class TerminassistentStateSetTimeslot {
  static readonly type = '[Terminassistent] Set time slot';

  constructor(public timeslot: any) {
  }
}

export class TerminassistentStateSetReservation {
  static readonly type = '[Terminassistent] Set reservation';

  constructor(public reservation: Partial<AxReservation>) {
  }
}

export class TerminassistentStateSetApiAppointmentSchedulerKey {
  static readonly type = '[Terminassistent] Set apiAppointmentSchedulerKey';

  constructor(public apiAppointmentSchedulerKey: string) {
  }
}

export class TerminassistentStateSetApiAppointmentSchedulerExternalKey {
  static readonly type = '[Terminassistent] Set apiAppointmentSchedulerExternalKey';

  constructor(public apiAppointmentSchedulerExternalKey: string) {
  }
}

export class TerminassistentStateSetRouteBeforeTerminassistentStart {
  static readonly type = '[Terminassistent] Set routeBeforeTerminassistentStart';

  constructor(public routeBeforeTerminassistentStart: string) {
  }
}

export class TerminassistentStateSetUtm {
  static readonly type = '[Terminassistent] Set UTM';

  constructor(public utm: string) {
  }
}

export class TerminassistentStateSetReferralCode {
  static readonly type = '[Terminassistent] Set referral code';

  constructor(public referralCode: string) {
  }
}
