<div [class.close]="!isVisible"
     [ngClass]="alertDialogOptions.feedbackState || 'none'"
     aria-labelledby="alertTitle"
     aria-modal="true"
     class="ax-ui-alert-dialog ax-dialog-overlay"
     role="dialog">
  <div class="ax-ui-alert-dialog__content">
    @if (alertDialogOptions.feedbackState !== 'none') {
      <div class="ax-ui-alert-dialog__state-icon">
        <ax-ui-icon [name]="stateIconName">
        </ax-ui-icon>
      </div>
    }
    <div>
      <div class="ax-dialog-overlay__title">
        <div class="ax-dialog-overlay__title-content">
          <p id="alertTitle"><b>{{ alertDialogOptions.title | translate }}</b></p>
          @if (alertDialogOptions.message) {
            <small [innerHTML]="alertDialogOptions.message | translate"></small>
          }
        </div>
        <ax-ui-icon (click)="close()"
                    [name]="'close'"
                    class="close-button">
        </ax-ui-icon>
      </div>
    </div>
  </div>

  @if (alertDialogOptions.actionLabel && alertDialogOptions.actionHandler) {
    <ax-ui-button (click)="executeAction()"
                  [text]="alertDialogOptions.actionLabel">
    </ax-ui-button>
  }
</div>
