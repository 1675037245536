<div class="ax-ui-button-group">
  <ul #buttonGroup class="ax-button-group"
      [class.hide]="buttonGroupOverflows"
      role="tablist">
    @for (button of buttonGroupOptions; track button; let i = $index) {
      <li role="presentation">
        <button (click)="setActiveButton(button.value)"
                [class.active]="button.value === activeButton"
                [attr.aria-selected]="button.value === activeButton"
                [attr.tabindex]="button.value === activeButton ? 0 : -1"
                [disabled]="allButtonsDisabled"
                [id]="'button-' + button.value"
                role="tab">
          {{ button.label }}
        </button>
      </li>
    }
  </ul>
  @if (buttonGroupOverflows) {
    <ax-ui-select (valueChanged)="setActiveButton($event)"
                  [disableHint]="true"
                  [hideHint]="true"
                  [maxWidth]="300"
                  [name]="'buttonOptionsMobile'"
                  [options]="selectOptions"
                  [disabled]="allButtonsDisabled"
                  [value]="activeButton"
                  class="ax-ui-button-group__select display-block">
    </ax-ui-select>
  }
</div>
