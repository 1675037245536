export * from './AuthenticatorFormGroup';

export * from './UpdateUseremploymentDtoFormGroup';

export * from './CreateUseremploymentDtoFormGroup';

export * from './UpdateUserDtoFormGroup';

export * from './CreateUserDtoFormGroup';

export * from './AddRoletypeDtoFormGroup';

export * from './RemoveRoletypeDtoFormGroup';

export * from './UpdateProjectDtoFormGroup';

export * from './UpdateProjectAnlageUndObjektdatenDtoFormGroup';

export * from './UpdateProjectFoerderungDtoFormGroup';

export * from './UpdateFixedDateDtoFormGroup';

export * from './UpdateMontageplanBlockDatesDtoFormGroup';

export * from './UpdateTaskDtoFormGroup';

export * from './CreatePhoneDtoFormGroup';

export * from './UpdatePhoneDtoFormGroup';

export * from './CreateNotificationDtoFormGroup';

export * from './UpdateNotificationDtoFormGroup';

export * from './SendMailDtoFormGroup';

export * from './SendLeadDtoFormGroup';

export * from './SendMailTemplateMetaFormGroup';

export * from './SendMailFromTemplateDtoFormGroup';

export * from './AuthenticateWithSecretDtoFormGroup';

export * from './UpdateWelcomescreenDtoFormGroup';

export * from './CreateDocumentDtoFormGroup';

export * from './CreateMailDtoFormGroup';

export * from './UpdateMailDtoFormGroup';

export * from './CreateInteractionDtoFormGroup';

export * from './CreateInteractionActivityDtoFormGroup';

export * from './CreateInteractionNoteDtoFormGroup';

export * from './CreateInteractionTaskDtoFormGroup';

export * from './UpdateInteractionDtoFormGroup';

export * from './CreateReservationDtoFormGroup';

export * from './UpdateReservationDtoFormGroup';

export * from './SendReservationFeedbackDtoFormGroup';

export * from './CreateAbsenceDtoFormGroup';

export * from './UpdateAbsenceDtoFormGroup';

export * from './CreatePushNotificationTokenDtoFormGroup';

export * from './CreateAppDeviceDtoFormGroup';

export * from './CreateAudienceDtoFormGroup';

export * from './AuthDtoFormGroup';

export * from './CreateCommentDtoFormGroup';

export * from './CreateElectricalMeasurementDtoFormGroup';

export * from './UpdateElectricalMeasurementDtoFormGroup';

export * from './CreateEventDtoFormGroup';

export * from './UpdateEventDtoFormGroup';

export * from './CreateEventInvitationDtoFormGroup';

export * from './CreateEventRegistrationSelectedOptionDtoFormGroup';

export * from './UpdateEventInvitationAdminDtoFormGroup';

export * from './UpdateEventInvitationOrganisatorDtoFormGroup';

export * from './CreateEventRegistrationOptionDtoFormGroup';

export * from './CreateEventRegistrationOptionGroupDtoFormGroup';

export * from './UpdateEventRegistrationOptionDtoFormGroup';

export * from './UpdateEventRegistrationOptionGroupDtoFormGroup';

export * from './UpdateProfileDtoFormGroup';

export * from './UpdateEventInvitationDtoFormGroup';

export * from './CreateUploadDtoFormGroup';

export * from './CreateUploadsDtoFormGroup';

export * from './UpdateAbsenceV2DtoFormGroup';

export * from './CreateAbsenceV2DtoFormGroup';

export * from './AddDocumentDtoFormGroup';

export * from './RemoveDocumentDtoFormGroup';

export * from './UpdateContactDtoFormGroup';

export * from './CreateContactDtoFormGroup';

export * from './CreateCreditorDtoFormGroup';

export * from './UpdateCreditorDtoFormGroup';

export * from './CreateDocumentV2DtoFormGroup';

export * from './UpdateDocumentV2DtoFormGroup';

export * from './SendDocumentsByEmailV2DtoFormGroup';

export * from './CreateEquipmentintervalDtoFormGroup';

export * from './UpdateEquipmentintervalDtoFormGroup';

export * from './CreateEquipmentlogDtoFormGroup';

export * from './CreateEquipmentlogCheckinDtoFormGroup';

export * from './CreateEquipmentlogCheckoutDtoFormGroup';

export * from './CreateEquipmentlogDefektDtoFormGroup';

export * from './CreateEquipmentlogReparaturDtoFormGroup';

export * from './UpdateEquipmentlogDtoFormGroup';

export * from './CreateEquipmentlogtypeDtoFormGroup';

export * from './UpdateEquipmentlogtypeDtoFormGroup';

export * from './CreateEquipmentDtoFormGroup';

export * from './UpdateEquipmentDtoFormGroup';

export * from './AddOrRemoveDocumentToEquipmentDtoFormGroup';

export * from './CreateEquipmenttypeDtoFormGroup';

export * from './UpdateEquipmenttypeDtoFormGroup';

export * from './UpdateEventRegistrationSelectedOptionDtoFormGroup';

export * from './CreateEventInvitationV2DtoFormGroup';

export * from './UpdateEventInvitationV2DtoFormGroup';

export * from './AddOrRemoveDocumentToEventDtoFormGroup';

export * from './UpdateLocationDtoFormGroup';

export * from './SendMessageDtoFormGroup';

export * from './CreateNewsDtoFormGroup';

export * from './UpdateNewsDtoFormGroup';

export * from './UpdatePermissionDtoFormGroup';

export * from './CreatePermissionDtoFormGroup';

export * from './CreatedReferralCodeDtoFormGroup';

export * from './WegspesenDetailsFormGroup';

export * from './WegspesenDtoFormGroup';

export * from './SendEmailToAgentsDtoFormGroup';

export * from './UpdateTimerecordBalanceDtoFormGroup';

export * from './UpdateRoletypeDtoFormGroup';

export * from './CreateRoletypeDtoFormGroup';

export * from './AddUserDtoFormGroup';

export * from './RemoveUserDtoFormGroup';

export * from './AddPermissionDtoFormGroup';

export * from './RemovePermissionDtoFormGroup';

export * from './UpdateStocklocationDtoFormGroup';

export * from './CreateStocklocationDtoFormGroup';

export * from './UpdateTagDtoFormGroup';

export * from './CreateTagDtoFormGroup';

export * from './CreateTaskDtoFormGroup';

export * from './CreateTimerecorddetailDtoFormGroup';

export * from './UpdateTimerecorddetailDtoFormGroup';

export * from './CreateTimerecordHolidayDtoFormGroup';

export * from './UpdateTimerecordHolidayDtoFormGroup';

export * from './UpdateTimerecordDtoFormGroup';

export * from './CreateOrUpdateUserconfigurationDtoFormGroup';

export * from './UpdateUsertrainingDtoFormGroup';

export * from './CreateUsertrainingDtoFormGroup';

