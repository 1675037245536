import { Type } from '@angular/core';
import { Routes } from '@angular/router';

export const getTabsRouting = (component: Type<any>): Routes => {
  return [
    {
      path: '',
      component: component,
    },
    {
      path: ':tab',
      component: component,
    },
    {
      path: ':tab/:subtab',
      component: component,
    },
  ];
};
