import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
  standalone: true,
})
export class GroupByPipe implements PipeTransform {
  transform(array: Array<any>, property: string): { amount: number; item: any; items: any[]; [key: string]: any }[] {
    const groupedObj = array.reduce((prev, curr) => {
      if (!(curr[property] in prev)) {
        prev[curr[property]] = [];
      }
      prev[curr[property]].push(curr);
      return prev;
    }, {});

    return Object.keys(groupedObj).map((propertyGroupBy) => ({
      [property]: propertyGroupBy,
      amount: groupedObj[propertyGroupBy].length,
      item: groupedObj[propertyGroupBy][0],
      items: groupedObj[propertyGroupBy],
    }));
  }
}
