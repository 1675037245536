import { Pipe, PipeTransform } from '@angular/core';
import { LabelVariant } from '../components/label/label.component';

@Pipe({
  name: 'getLabelVariantForStatus',
  standalone: true,
})
export class GetLabelVariantForStatusPipe implements PipeTransform {
  transform(statusOrStatusId: number | string | null | undefined): LabelVariant {
    /**
     * Allow either statusId (from Status Table) or Text (e.g. used in Tasks, unfortunately).
     */
    switch (statusOrStatusId) {
      case 'Aktiv':
      case 'Beendet':
      case 'Genehmigt':
      case 'Erledigt':
      case 1:
      case 7:
      case 9:
      case 13:
      case 16:
        return 'success';
      case 'Passiv':
      case 'Nicht realisiert':
      case 'Zurückgezogen':
      case 2:
      case 8:
      case 11:
        return 'neutral';
      case 'Planung':
      case 'Ausführung':
      case 'Administrativer Abschluss':
      case 'Offen':
      case 'Definitiv':
      case 'Freigegeben':
      case 4:
      case 5:
      case 6:
      case 12:
      case 15:
        return 'warning';
      case 'Abgelehnt':
      case 10:
      case 14:
        return 'error';
      default:
        return 'neutral';
    }
  }
}
