import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from '../inputs/select/select.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectDropdownOptions } from '../../models/select-dropdown-options.model';
import { ButtonGroupOptions } from '../../models/button-group-options.model';
import { ActivatedRoute, Router } from '@angular/router'; // Import Router and ActivatedRoute
import * as dayjs from 'dayjs';

@Component({
  selector: 'ax-ui-button-group',
  standalone: true,
  imports: [CommonModule, SelectComponent, TranslateModule],
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.scss',
})
export class ButtonGroupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('buttonGroup') buttonGroup!: ElementRef;

  @Input() buttonGroupOptions: ButtonGroupOptions[] = [];
  @Input({ required: true }) activeButton!: number | string; // Ensure the activeTab is managed as an input
  @Input() allButtonsDisabled = false;
  @Input() monthsFilter = false;
  @Input() yearRangeFilter: number | undefined; // range of years to show before & after current year
  @Input({ required: true }) routeFragmentName: string | undefined; // range of years to show before & after current year

  @Output() activeButtonChange: EventEmitter<any> = new EventEmitter<any>();

  public buttonGroupOverflows = false;
  public selectOptions: SelectDropdownOptions[] = [];
  private tabGroupResizeObserver!: ResizeObserver;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    // Read active button from query params if available
    this.route.queryParams.subscribe((params) => {
      if (this.routeFragmentName) {
        if (params[this.routeFragmentName]) {
          const paramValue = params[this.routeFragmentName];
          const parsedValue = !isNaN(Number(paramValue)) ? Number(paramValue) : paramValue;
          this.setActiveButton(parsedValue);
        }
      }
    });

    if (this.monthsFilter) {
      this.prepareMonthsFilter();
    }
    if (this.yearRangeFilter) {
      this.prepareYearRangeFilter();
    }

    this.populateSelectOptions();
  }

  ngAfterViewInit() {
    this.tabGroupResizeObserver = new ResizeObserver(() => {
      this.buttonGroupOverflows = this.buttonGroupHasOverflow();
    });

    this.tabGroupResizeObserver.observe(this.buttonGroup.nativeElement);
  }

  ngOnDestroy() {
    this.tabGroupResizeObserver?.disconnect();
  }

  public setActiveButton(buttonValue: number | string) {
    this.activeButton = buttonValue;
    this.activeButtonChange.emit(buttonValue);

    // Save active button to the URL query params
    if (this.routeFragmentName) {
      this.router
        .navigate([], {
          queryParams: { [this.routeFragmentName]: buttonValue },
          queryParamsHandling: 'merge', // Merge with existing query params
        })
        .catch();
    }
  }

  public prepareMonthsFilter() {
    this.buttonGroupOptions = [];
    for (let i = 1; i <= 12; i++) {
      this.buttonGroupOptions.push({
        label: dayjs()
          .month(i - 1)
          .format('MMM'),
        value: i,
      });
    }
  }

  public prepareYearRangeFilter() {
    if (this.yearRangeFilter) {
      for (let i = -this.yearRangeFilter; i <= this.yearRangeFilter; i++) {
        const year = dayjs().add(i, 'year').year();
        this.buttonGroupOptions.push({ label: `${year}`, value: year });
      }
    }
  }

  public populateSelectOptions() {
    this.selectOptions = [];
    this.buttonGroupOptions.forEach((button) => {
      this.selectOptions.push({
        label: button.label,
        value: button.value,
      });
    });
  }

  private buttonGroupHasOverflow() {
    const element = this.buttonGroup.nativeElement;
    return element.scrollWidth > element.clientWidth;
  }
}
