<div class="ax-ui-filter">
  <div class="ax-ui-filter__button">
    <ax-ui-button (buttonClick)="filterDropdown.toggleDropDown()"
                  [active]="filterDropdown.dropDownOpen"
                  [additionalInfoText]="numberOfSelectedFiltersText"
                  [disabled]="filterDropdownOptions && !filterDropdownOptions.length || disabled"
                  [iconPositionRight]="true"
                  [small]="smallButton"
                  [text]="selectedFiltersButtonText"
                  iconName="filter_list"
                  variant="secondary" />
    <ax-ui-dropdown-list #filterDropdown
                         (optionRemoved)="removeFilter($event)"
                         (optionSelected)="addActiveFilter($event)"
                         (selectedCheckboxOptionsChange)="onSelectedCheckboxOptionsChange()"
                         [alignment]="dropDownAlignment"
                         [listTitle]="filterTitle"
                         [options]="filterDropdownOptions"
                         [withCheckboxes]="true"
                         [preventActiveHighlighting]="true" />
  </div>
</div>
