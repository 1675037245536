import { Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'ax-ui-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  imports: [
    TranslateModule
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() checked = false;
  @Input() id = '';
  @Input() name = '';
  @Input() label = '';
  @Input() labelBold = false;
  @Input() disabled = false;
  @Input() readonly = false;
  @Input() required = false;
  @Input() formControlName = '';
  @Input() noMargin = false;

  @Output() valueChanged = new EventEmitter<boolean>();

  private formControl: FormControl | undefined;

  constructor(
    @Optional() private controlContainer: ControlContainer
  ) {
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  ngOnInit() {
    if (!this.name) {
      this.name = this.id;
    }
    this.setOptionsFromFormControl();
  }

  /**
   * Give the Checkbox the correct value on click
   */
  toggleCheckbox(): void {
    this.checked = !this.checked;
    this.onChange(this.checked);
    this.onTouched();
    this.valueChanged.emit(this.checked);
  }

  writeValue(obj: any): void {
    this.checked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.required && this.checked === undefined ? { 'required': true } : null;
  }

  setOptionsFromFormControl() {
    if (this.controlContainer && this.formControlName) {
      this.formControl = this.controlContainer.control?.get(this.formControlName) as FormControl;
      if (this.formControl) {
        if (this.formControl.value) {
          this.checked = this.formControl.value;
        }
        if (this.formControl.validator) {
          const validator = this.formControl.validator({} as AbstractControl);
          this.required = validator && validator['required'];
        }
      }
    }
  }
}
