import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output, SimpleChanges,
  SkipSelf,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { DateFilterFn, MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { DayjsDateAdapter } from './dayjs-date-adapter.service';
import { MatInputModule } from '@angular/material/input';
import * as dayjs from 'dayjs';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

export const PICKER_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD',
    monthYearA11yLabel: 'YYYY',
  },
};

export type rangeType = 'startDate' | 'endDate';

@Component({
  selector: 'ax-ui-input-datepicker',
  standalone: true,
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-CH',
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: PICKER_DATE_FORMATS,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatepickerComponent),
      multi: true,
    },
  ],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  templateUrl: './input-datepicker.component.html',
  styleUrl: './input-datepicker.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InputDatepickerComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {
  @ViewChild('startDateInput') startDateInput: ElementRef | undefined;
  @ViewChild('endDateInput') endDateInput: ElementRef | undefined;
  @ViewChild('singleDateInput') singleDateInput: ElementRef | undefined;

  // general
  @Input() variant: 'dateSingle' | 'dateRange' = 'dateSingle';
  @Input() label = '';
  @Input() minDate: Date | string | null = null;
  @Input() maxDate: Date | string | null = null;
  @Input() disabled = false;
  @Input() disableHint = false;
  @Input() required = false;
  @Input() maxWidth: number | string | undefined;
  private inputDebounceTime = 500;

  // single date
  @Input() singleDate: string | null = '';
  @Input() singleDatePlaceholder = 'DD.MM.YYYY';
  @Input() singleDateFormControlName = '';
  @Output() singleDateChange = new EventEmitter<string | null>();
  public singleDateFormControl = new FormControl(dayjs().format('DD.MM.YYYY'));
  private singleDateInputSubject: Subject<string> = new Subject<any>();

  // date range
  @Input() rangeStartDate: string | null = '';
  @Input() rangeEndDate: string | null = '';
  @Input() rangeStartDatePlaceholder = '';
  @Input() rangeEndDatePlaceholder = '';
  @Input() rangeStartDateFormControlName = 'startDate';
  @Input() rangeEndDateFormControlName = 'endDate';

  @Output() rangeStartDateChange = new EventEmitter<string | null>();
  @Output() rangeEndDateChange = new EventEmitter<string | null>();
  public rangeDateForm: FormGroup<{ [key: string]: FormControl }> = new FormGroup({});
  private rangeDateInputSubject: Subject<{ value: string, type: rangeType }> = new Subject<any>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Optional() @SkipSelf() private controlContainer: ControlContainer,
  ) {
  }


  ngOnInit() {
    if (this.variant === 'dateSingle') {
      this.singleDateInputSubject.pipe(
        debounceTime(this.inputDebounceTime),
        distinctUntilChanged(),
      ).subscribe((value: string) => {
        this.processInput(value);
      });
      if (this.singleDate) {
        this.writeValue(this.singleDate);
        this.singleDateFormControl.setValue(this.singleDate);
      }
    } else if (this.variant === 'dateRange') {
      this.rangeDateInputSubject.pipe(
        debounceTime(this.inputDebounceTime),
        distinctUntilChanged(),
      ).subscribe((result) => {
        this.handleRangeDateChange(result.value, result.type);
      });
      const parentFormGroup = this.controlContainer?.control as FormGroup;
      if (parentFormGroup) {
        this.rangeDateForm = parentFormGroup;
      } else {
        this.rangeDateForm.addControl(this.rangeStartDateFormControlName, new FormControl(this.rangeStartDate));
        this.rangeDateForm.addControl(this.rangeEndDateFormControlName, new FormControl(this.rangeEndDate));
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.variant === 'dateSingle') {
      const parentFormGroup = this.controlContainer?.control as FormGroup;
      if (parentFormGroup) {
        this.singleDateFormControl = parentFormGroup.controls[this.singleDateFormControlName] as FormControl;
      }

      this.required = this.singleDateFormControl.hasValidator(Validators.required);
      if (!this.singleDate && this.singleDateFormControl.value) {
        this.singleDate = this.singleDateFormControl.value;
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['rangeStartDate']?.currentValue) {
      this.setRangeControlValue(this.rangeStartDateFormControlName, this.rangeStartDate);
    }

    if (changes['rangeEndDate']?.currentValue) {
      this.setRangeControlValue(this.rangeEndDateFormControlName, this.rangeEndDate);
    }
  }

  public onRangeInput(event: Event, rangeType: rangeType) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    const formControl = this.rangeDateForm.controls[rangeType === 'startDate' ? this.rangeStartDateFormControlName : this.rangeEndDateFormControlName];
    formControl.setErrors(null);
    this.rangeDateInputSubject.next({ value, type: rangeType });
  }

  public handleRangeDateChange(value: any, rangeType: rangeType): void {
    let controlName = this.rangeStartDateFormControlName;
    let changeEmitter = this.rangeStartDateChange;
    if (rangeType === 'endDate') {
      controlName = this.rangeEndDateFormControlName;
      changeEmitter = this.rangeEndDateChange;
    }
    const formControl = this.rangeDateForm.controls[controlName];
    formControl.setErrors(null);

    // value from datepicker
    if (dayjs.isDayjs(value)) {
      value = dayjs(value).format('YYYY-MM-DD');
      formControl.setValue(value);
      changeEmitter.emit(value);
      return;
    }

    // value from input
    const isValidInput = this.isValidInputDateString(value);
    if (isValidInput) {
      value = this.getParsedAndFormattedDate(value);
      formControl.setValue(value);
      changeEmitter.emit(value);
    } else {
      formControl.setErrors({ invalidDate: true });
    }
  }

  private setRangeControlValue(controlName: string, value: any): void {
    const control = this.rangeDateForm.controls[controlName];
    if (control) {
      control.setValue(value);
    }
  }

  // check if field has full date formats DD.MM.YYYY, DD/MM/YYYY, D.M.YYYY, D/M/YYYY, D.MM.YYYY, DD.M.YYYY, etc.
  private isValidInputDateString(date: string): boolean {
    const datePattern = /^(0?[1-9]|[12][0-9]|3[01])[./](0?[1-9]|1[012])[./]\d{4}$/;
    return datePattern.test(date);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: any) => void = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched: () => void = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    if (value) {
      this.singleDate = dayjs(value).format('YYYY-MM-DD');
    }
  }

  onDateSingleChange(event: MatDatepickerInputEvent<Date> | string): void {
    const eventValue = typeof event === 'string' ? event : event.value;
    const effectiveDate = eventValue === '' ? null : dayjs(eventValue).format('YYYY-MM-DD');
    if (effectiveDate !== 'Invalid Date') {
      this.singleDateFormControl.setValue(effectiveDate);
      this.onChange(effectiveDate);
      this.singleDateChange.emit(effectiveDate);
    }
    this.onTouched();
    this.validateInput();
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    this.singleDateFormControl.setErrors(null);
    this.singleDateInputSubject.next(value);

  }

  protected dateFilter: DateFilterFn<Date | null> = (d: Date | null) => {
    if (!d) {
      return true;
    }
    const minDate = this.minDate ? dayjs(this.minDate) : undefined;
    const maxDate = this.maxDate ? dayjs(this.maxDate) : undefined;
    if (minDate && maxDate) {
      return (d >= minDate.toDate() && d <= maxDate.toDate());
    } else if (minDate) {
      return (d >= minDate.toDate());
    } else if (maxDate) {
      return (d <= maxDate.toDate());
    } else {
      return true;
    }
  };

  private processInput(value: string): void {
    if (this.isValidInputDateString(value)) {
      const formattedDate = this.getParsedAndFormattedDate(value);
      this.singleDateFormControl.setValue(formattedDate);
    } else if (value.length >= 8) {
      this.validateInput();
    }
  }

  private getParsedAndFormattedDate(value: string): string {
    const parsedDate = dayjs(value, 'D.M.YYYY');
    return parsedDate.format('YYYY-MM-DD');
  }

  private validateInput() {
    const inputFieldValue = this.singleDateInput?.nativeElement.value;
    if (this.singleDateFormControl.value === null && inputFieldValue !== '') {
      this.singleDateFormControl.setErrors({ invalidDate: true });
    }
  }
}
