import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
  pure: false,
  standalone: true,
})
export class SortByPipe implements PipeTransform {
  transform(items: any[], key: any, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!Array.isArray(items) || !key) {
      return items;
    }

    return items.sort((a, b) => {
      if (a[key] === undefined || b[key] === undefined) return 0;

      const aValue = a[key];
      const bValue = b[key];

      let comparison = 0;

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        comparison = aValue.localeCompare(bValue);
      } else if (typeof aValue === 'number' && typeof bValue === 'number') {
        comparison = aValue - bValue;
      } else if (aValue > bValue) {
        comparison = 1;
      } else if (aValue < bValue) {
        comparison = -1;
      }

      return order === 'asc' ? comparison : -comparison;
    });
  }
}
